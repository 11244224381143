// spacing
// $content-breathing-room: 17px;

// topbar
$topbar-height: 32px;
$topbar-background-color: $light;
$topbar-border-color: $dark;
$topbar-toggle-padding: 1em;

// navbar
:root {
--navbar-width: 52px;
--content-breathing-room: 3px;
@include until($tablet) {
    --navbar-width: 0px;
    --content-breathing-room: 2px;
}
}
$navbar-background-color: $light;
$navbar-active-icon-background-color: $info;
$navbar-active-icon-color: $white;
$navbar-icon-hover-background-color: $link;
$navbar-z-index: 4;

// widgets
$widget-background-color: $white;
$widget-title-color: $dark;
$widget-title-font-size: 16px;
$widget-border: solid 1px $grey-light;
$widget-border-radius: 0px;
$widget-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.5);
$group-by-font-size: 12px;
$map-theme-color: $info;
$zoom-icon-height: 2rem;
$zoom-icon-width: 2rem;
$map-tooltip-background-color: $white;
$map-tooltip-border: solid 1px $info;
$map-tooltip-font-size: 1em;
$map-tooltip-label-font-weight: bold;
$map-tooltip-value-font-weight: light;
$map-tooltip-breathing-room: 0.5em;


// date selector
$date-drawer-height: 250px;
$date-drawer-background-color: $light;
$datepicker-breathing-room: 3em;
$date-option-width: fit-content;

// landing page
$landing-page-link-breathing-room: 20px;
$landing-page-link-border: solid 1px $grey-light;
$landing-page-link-border-radius: 3px;